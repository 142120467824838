<template>
    <div class="FWZBox">
        <div class="ConBox" v-for="(item,index) in list" :key="index">
            <div class="Name" :style="active==index?'display:none':'display:block'" @click="changIdx(index)">{{ item.title }}</div>
            <div class="ImgBox" style="width: 200px;height: 200px;" :style="active==index?'display:block':'display:none'" @click="goDetail(item,index)">
                <img :src="item.logoUrl" style="width: 100%;height: 100%;">
                <div>{{ item.title }}</div>
            </div>
        </div>
    </div>

    <Detail v-if="detailShow" class="Detail" :detail="current"></Detail>

</template>
  
<script>
import $ from 'jquery'
import Detail from '../../components/IntangibleCultural/Detail.vue'
export default {
    props: {
        list: {
            tyle: Object,
            default: []
        }
    },
    components: { Detail },
    data () {
        return {
            active: 0,
            detailShow: false,
            current: {},
        }
    },
    mounted () {
    },
    methods: {
        changIdx (idx) {
            this.active = idx
        },
        goDetail (data, idx) {
            this.current = {
                title: data.title,
                summary: data.summary,
                logoUrl: data.logoUrl,
                heritageId: data.heritageId
            }
            this.detailShow = true
            setTimeout(() => {
                $('.Detail').fadeIn(500)
            }, 100)
        }

    },
}
</script>
  
<style lang="less" scoped>
@import '../../assets/unify.less';
.FWZBox {
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    .ConBox {
        color: #222;
        position: relative;
        cursor: pointer;
        margin: 0 2rem;
        transform-origin: center bottom;
        .Name {
            position: relative;
            top: 30%;
            padding: 25px 0 30px;
            font-size: @Content_Text_Size;
            line-height: 1.375;
            white-space: nowrap;
            background-size: 100% 100%;
            display: inline-block;
            writing-mode: vertical-lr;
            z-index: 10;
            background: url('../../assets/Scenicspace/new/nameBG.png') repeat;
        }
        .ImgBox {
            display: none;
            text-align: center;
            // background-color: #083d76;
            position: relative;
            top: 20%;
            img {
                border-radius: 50%;
            }
            div {
                position: absolute;
                bottom: 90px;
                font-size: @Content_Text_Size;
                left: 0;
                right: 0;
                background: rgba(0, 0, 0, 0.5);
                height: 20px;
                color: #fff;
            }
        }
        .Name:before {
            top: 0;
            content: '';
            position: absolute;
            left: 0;
            width: 22px;
            height: 12px;
            background: url('../../assets/Scenicspace/new/fybg.png') no-repeat
                left top;
            // background-size: 100% 100%;
            // transform: rotate(45deg);
        }
        .Name:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 22px;
            height: 12px;
            background: url('../../assets/Scenicspace/new/fybg.png') no-repeat
                right top;
            // background-size: 100% 100%;
            // transform: rotate(135deg);
        }
    }
    .ConBox:before {
        content: '';
        position: absolute;
        right: 50%;
        top: 0;
        bottom: 0;
        border-left: 1px solid #ccc;
        z-index: 0;
    }
}
</style>